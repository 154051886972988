/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 
No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import GlobalStyles from "./GlobalStyles";
import AnimationRevealPage from "./components/AnimationRevealPage.js";
import Hero from "./components/TwoColumnWithVideo.js";
import Features from "./components/ThreeColWithSideImage.js";
// import TabGrid from "./components/TabCardGrid.js";
import TeamCardGrid from "./components/ProfileThreeColGrid.js";
import DownloadApp from "./components/DownloadApp.js";
import GetStarted from "./components/GetStartedLight.js";
import SimpleFooter from "./components/footers/SimpleFiveColumn.js";

const App = () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-sky-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-sky-500 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-3xl`;
  return (
    <>
      <GlobalStyles />
      <AnimationRevealPage disabledChildren={[0, 5]}>
        <Hero
          heading={
            <>
              Personal Assistant for{" "}
              <HighlightedText>Life Long Learning</HighlightedText>
            </>
          }
          description="PAL3 is an adaptive learning framework which uses AI to give you personal lesson recommendations based on a personalized learning roadmap. For now PAL3 teaches you about AI, but more topics are coming soon!"
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="Get Started"
          primaryButtonUrl={`${process.env.REACT_APP_WWW}/web`}
          watchVideoYoutubeUrl={`${process.env.REACT_APP_WWW}/media/videos/intro.mp4`}
        />
        <Features
          subheading={<Subheading></Subheading>}
          heading={
            <>
              <HighlightedText>AI</HighlightedText> for Learning and Reaching
              Your Goals
            </>
          }
          description=" "
          cards={[
            {
              title: "Personalized",
              description: "Your own roadmap, based on your goals and progress",
            },
            {
              title: "Learning Models",
              description:
                "Tracking skills and forgetting to optimize learning",
            },
            {
              title: "Intelligent Tutoring",
              description:
                "Conversational tutors and virtual mentors to build concepts and skills",
            },
          ]}
        />
        {/* <TabGrid
          heading={
            <>
              Study these <HighlightedText>Goals</HighlightedText>
            </>
          }
        /> */}
        <TeamCardGrid
          subheading={<Subheading></Subheading>}
          heading="Team and Institutions"
          description=" "
        />
        <DownloadApp
          text={
            <>
              Download the{" "}
              <HighlightedTextInverse>PAL3 GAI</HighlightedTextInverse> app and
              start learning now!
            </>
          }
          link1Url="https://apps.apple.com/us/app/pal3-game-if-ai-gai/id6447142845"
          link2Url="https://play.google.com/store/apps/details?id=com.pal3gai"
        />
        <GetStarted
          subheading="Don't want to install?"
          heading="Join the website instead."
          primaryLinkText="Sign Up"
          primaryLinkUrl={`${process.env.REACT_APP_WWW}/web/signup`}
          secondaryLinkText="Log In"
          secondaryLinkUrl={`${process.env.REACT_APP_WWW}/web/login`}
        />
        <SimpleFooter />
      </AnimationRevealPage>
    </>
  );
};

export default App;
