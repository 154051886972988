/*
Government Purpose Rights (“GPR”)
Contract No.  W911NF-14-D-0005
Contractor Name:   University of Southern California
Contractor Address:  3720 S. Flower Street, 3rd Floor, Los Angeles, CA 90089-0001
Expiration Date:  Restrictions do not expire, GPR is perpetual
Restrictions Notice/Marking: The Government's rights to use, modify, reproduce, release, perform, display, or disclose this software are restricted by paragraph (b)(2) of the Rights in Noncommercial Computer Software and Noncommercial Computer Software Documentation clause contained in the above identified contract.  No restrictions apply after the expiration date shown above. Any reproduction of the software or portions thereof marked with this legend must also reproduce the markings. (see: DFARS 252.227-7014(f)(2)) 
No Commercial Use: This software shall be used for government purposes only and shall not, without the express written permission of the party whose name appears in the restrictive legend, be used, modified, reproduced, released, performed, or displayed for any commercial purpose or disclosed to a person other than subcontractors, suppliers, or prospective subcontractors or suppliers, who require the software to submit offers for, or perform, government contracts.  Prior to disclosing the software, the Contractor shall require the persons to whom disclosure will be made to complete and sign the non-disclosure agreement at 227.7103-7.  (see DFARS 252.227-7025(b)(2))
*/
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/ict-logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "../../images/github-icon.svg";

const Container = tw.div`relative bg-black text-white -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 pb-8 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-blue-500 hocus:border-blue-500 pb-1 transition duration-300`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const SimpleFiveColumn = () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <img alt="ICT Logo" src={LogoImage} style={{ width: 300 }} />
          <CompanyDescription>
            The USC Institute for Creative Technologies (ICT) is a Department of
            Defense (DoD) University Affiliated Research Center (UARC),
            sponsored by the US Army.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/USCICT">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://x.com/usc_ict">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/uscict">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/usc-institute-for-creative-technologies">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink href="https://github.com/ictlearningsciences">
              <GithubIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={`${process.env.REACT_APP_WWW}/web/login`}>
                Log In
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={`${process.env.REACT_APP_WWW}/web/signup`}>
                Sign Up
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={`${process.env.REACT_APP_WWW}/admin`}>
                Admin Portal
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Resources</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://mentorpal.org">MentorPal</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://opentutor.info">OpenTutor</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://gameifai.org/">Game-if-AI</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://inots.org/#/">INOTS</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Legal</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://ict.usc.edu/about-us/">About Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://ict.usc.edu/about-us/sponsorship/">
                Funding & Support
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={`${process.env.REACT_APP_WWW}/privacy.html`}>
                Privacy Policy
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <p
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          width: "100%",
          color: "white",
          fontSize: 10,
          textAlign: "center",
          borderTop: "red",
          borderTopStyle: "solid",
          borderTopWidth: 1,
        }}
      >
        The project or effort depicted was or is sponsored by the U.S.
        Government and that the content of the information does not necessarily
        reflect the position or the policy of the Government, and no official
        endorsement should be inferred.
      </p>
    </Container>
  );
};

export default SimpleFiveColumn;
